@mixin font-smoothing($value) {
  @if ($value == antialiased) {
    -webkit-font-smoothing:   antialiased;
    -moz-osx-font-smoothing:  greyscale;
  } @else if ($value == none) {
    -webkit-font-smoothing:   none;
    -moz-osx-font-smoothing:  none;
  } @else {
    -webkit-font-smoothing:   subpixel-antialiased;
    -moz-osx-font-smoothing:  auto;
  }
}

// UNGC icons Font
@font-face {
  font-family: "ungc-icons";
  font-style: normal;
  font-weight: normal;
  src: url("https://d306pr3pise04h.cloudfront.net/fonts/fontello-0ecdb99f/ungc-icons.eot?#iefix") format("embedded-opentype"), url("https://d306pr3pise04h.cloudfront.net/fonts/fontello-0ecdb99f/ungc-icons.woff") format("woff"), url("https://d306pr3pise04h.cloudfront.net/fonts/fontello-0ecdb99f/ungc-icons.svg#ungc-icons") format("svg");
}

// Logos
$logos: (
  'ungc': '\e800'
);

// Icons
$icons: (
  'xls': '\e801',
  'pdf': '\e802',
  'ppt': '\e803',
  'doc': '\e804'
);

// Base icon styles
%icon {
  font-family: "ungc-icons";
  font-weight: normal;
  @include font-smoothing(antialiased)
}

// functions
@function icon($icon-name) {
  @return map-get($icons, $icon-name);
}

@function logo($logo-name) {
  @return map-get($logos, $logo-name);
}

// mixins
@mixin icon($icon-name: false) {
  @extend %icon;
  @if $icon-name {
    content: icon($icon-name);
  }
}

@mixin logo($logo-name: false) {
  @extend %icon;
  @if $logo-name {
    content: logo($logo-name);
  }
}
